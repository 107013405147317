import * as React from 'react';

function QAndA() {
  return (
    <svg
      width="270"
      height="193"
      viewBox="0 0 270 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M149.8 0H6C4.4087 0 2.8826 0.632136 1.75739 1.75735C0.632167 2.88257 0 4.4087 0 6V111.69C0 113.281 0.632167 114.807 1.75739 115.933C2.8826 117.058 4.4087 117.69 6 117.69H21.8V141.52L51.22 117.65H149.8C151.391 117.65 152.917 117.018 154.043 115.893C155.168 114.767 155.8 113.241 155.8 111.65V6C155.8 4.4087 155.168 2.88257 154.043 1.75735C152.917 0.632136 151.391 0 149.8 0Z"
        fill="#36D1B7"
      />
      <path
        d="M263.599 50.9502H119.799C118.208 50.9502 116.681 51.5823 115.556 52.7076C114.431 53.8328 113.799 55.3589 113.799 56.9502V162.64C113.799 164.231 114.431 165.758 115.556 166.883C116.681 168.008 118.208 168.64 119.799 168.64H218.379L247.799 192.52V168.66H263.599C265.19 168.66 266.716 168.028 267.841 166.903C268.967 165.778 269.599 164.252 269.599 162.66V56.9502C269.599 55.3589 268.967 53.8328 267.841 52.7076C266.716 51.5823 265.19 50.9502 263.599 50.9502Z"
        fill="#2D9CDB"
      />
      <path
        d="M98.9995 79.75L95.1295 84.06L88.5295 78.3C86.8882 79.2509 85.1159 79.9552 83.2695 80.39C81.5026 80.8042 79.6942 81.0155 77.8795 81.02C74.7487 81.0736 71.6448 80.4351 68.7895 79.15C66.1781 77.9613 63.8549 76.2215 61.9795 74.05C60.0804 71.8358 58.6271 69.2755 57.6995 66.51C56.6945 63.5544 56.1909 60.4516 56.2095 57.33C56.1943 54.2249 56.6978 51.1391 57.6995 48.2C58.6314 45.4363 60.0843 42.8769 61.9795 40.66C63.8645 38.4912 66.1847 36.7433 68.7895 35.53C71.6397 34.2262 74.7456 33.577 77.8795 33.63C81.0133 33.577 84.1192 34.2262 86.9695 35.53C89.5758 36.7407 91.8966 38.489 93.7795 40.66C95.6747 42.8769 97.1276 45.4363 98.0595 48.2C99.0611 51.1391 99.5647 54.2249 99.5495 57.33C99.546 58.9108 99.419 60.4889 99.1695 62.05C98.9197 63.629 98.5384 65.1844 98.0295 66.7C97.5177 68.2131 96.8473 69.6679 96.0295 71.04C95.2468 72.3948 94.266 73.6251 93.1195 74.69L98.9995 79.75ZM78.4095 69.52L82.2095 65.15L87.7195 69.96C89.1375 68.2392 90.1615 66.2288 90.7195 64.07C91.3106 61.8823 91.6098 59.6261 91.6095 57.36C91.6136 55.2218 91.3482 53.0917 90.8195 51.02C90.3341 49.0456 89.5084 47.1709 88.3795 45.48C87.285 43.8566 85.8173 42.5193 84.0995 41.58C82.1788 40.567 80.0302 40.0643 77.8595 40.12C75.6888 40.0643 73.5402 40.567 71.6195 41.58C69.9017 42.5193 68.434 43.8566 67.3395 45.48C66.2097 47.1692 65.387 49.0446 64.9095 51.02C64.3774 53.0913 64.1086 55.2214 64.1095 57.36C64.1093 59.4952 64.378 61.622 64.9095 63.69C65.3889 65.6678 66.2113 67.5462 67.3395 69.24C68.4373 70.8579 69.9044 72.1913 71.6195 73.13C73.5402 74.1429 75.6888 74.6456 77.8595 74.59C78.722 74.5851 79.5822 74.5014 80.4295 74.34C81.294 74.1679 82.1338 73.8891 82.9295 73.51L78.4095 69.52Z"
        fill="white"
      />
      <path
        d="M187.449 83.1401H195.879L213.299 128.38H204.809L200.569 116.38H182.529L178.289 128.38H170.119L187.449 83.1401ZM184.669 110.39H198.529L191.749 90.9301H191.529L184.669 110.39Z"
        fill="white"
      />
    </svg>
  );
}

export default QAndA;
